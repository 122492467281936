@import "~bulma/sass/utilities/mixins.sass";

.full-screen-bottom {
  display: none;
  @include mobile {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 1rem 2rem;
    .bottom-button {
      margin-top: 0 !important;
    }
  }
}
