@import "../../node_modules/bulma/sass/utilities/mixins.sass";
@import "../mixins";

.button.is-primary.is-dusk:not(.is-submit) {
  @include mobile() {
    width: 100%;
  }
  @include tablet() {
    padding-left: 3em;
    padding-right: 3em;
  }

  height: 52px;
}

.button.is-dusk.is-hero {
  border-color: transparent;
  border-radius: 4px;
  @include gotham-text;
  font-size: 14px;
  line-height: 2;
  height: 32px;
  width: initial;
  @include tablet() {
    font-size: 18px;
    padding-left: 1em;
    padding-right: 1em;
    height: 52px;
  }
}

.button.is-rounded.is-dusk {
  border-color: transparent;
  border-radius: 4px;
  @include gotham-text;
  @include mobile() {
    width: 100%;
  }
  @include tablet {
    border-radius: 15px;
    padding-left: 3em;
    padding-right: 3em;
  }
  &.is-large {
    height: 32px;
    font-size: 14px;
    @include tablet {
      height: 67px;
      font-size: 20px;
    }
  }
}

.button.is-submit {
  height: 33px;
  @include tablet {
    height: 47px;
  }
}

.button.is-small:not(.is-submit) {
  height: 32px !important;
  @include mobile() {
    width: initial !important;
  }
}

.button.is-rounded-15 {
  border-radius: 15px;

  &.is-small {
    border-radius: 5px;
  }
}

.button.is-success {
  background-color: #8f8f8f !important;
}
