.city-select-control {
  border: 1px solid black !important;
  height: 47px;
  font-size: 20px;
  border-radius: 15px !important;
  background: transparent !important;
  text-align: left;
  padding-left: 0.7em;
}

.city-select-placeholder,
.city-select-input,
.city-select-option {
  color: black !important;
  padding-left: 0.5em;
}

.city-select-option-focused {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.city-select-option-selected {
  background-color: black !important;
  color: white;
}
