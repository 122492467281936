@import "../../../node_modules/bulma/sass/utilities/mixins.sass";

.feature-icon {
  // margin-top: 0.5rem;
  width: 20px;
  height: 20px;

  @include tablet {
    margin-top: initial;
    width: 40px;
    height: 40px;
  }
}

.feature-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  .column {
    z-index: 1;
  }
  .feature-video-container {
    position: relative;
    @include mobile {
      height: 600px;
    }
    video {
      height: 100%;
      position: absolute;
      top: 5%;
      left: 20%;
      z-index: 0;
    }
  }
}
