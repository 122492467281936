.recommended-bar-image {
  height: 236px;
  width: auto;
  border: 3px solid #fff;
}

.recommended-bar-text {
  max-width: 236px;
  margin: 0 auto;
  height: 30px;
}

.blur {
  filter: blur(8px);
  border: none !important;
}
