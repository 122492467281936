.icon-description-container {
    padding: 0.5rem;
}

.icon-description-image-container {
    display: inline-block;
    vertical-align: top;
}

.icon-description-image {
    width: 28px;
    max-height: 24px;
    object-fit: contain;
}

.icon-description-text {
    max-width: calc(100% - 36px);
    padding: 0 0.5rem 0 1rem;
    vertical-align: middle;
    line-height: 30px;
}