@import "../../node_modules/bulma/sass/utilities/mixins.sass";

.hero-body.bottom-align {
  padding-top: 18rem !important;
  padding-bottom: 2rem !important;
}

.hero.full-height {
  @include mobile {
    height: calc(100vh - 148px);
    margin-bottom: 92px;
    .hero-body {
      position: absolute;
      bottom: 0px;
    }
  }
}

.full-height .bottom-align {
  @include mobile() {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.hero.is-large .hero-body {
  @include tablet {
    padding-top: 1rem !important;
  }
}

.hero.is-large .hero-body.bottom-align {
  @include tablet() {
    padding-top: 30rem !important;
    padding-bottom: 2rem !important;
  }
}

.hero-body .subtitle {
  line-height: 1.75;
}

.hero-body .button,
.section .button {
  margin-top: 0.5em;
}
