/****************************
 HIGH PPI DISPLAY BACKGROUNDS
*****************************/

@mixin background-2x(
  $path,
  $ext: "png",
  $pos: center center,
  $repeat: no-repeat
) {
  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");
  background-size: cover;
  background-position: $pos;
  background-repeat: $repeat;

  @media all and (-webkit-min-device-pixel-ratio: 1.5),
    all and (-o-min-device-pixel-ratio: 3/2),
    all and (min--moz-device-pixel-ratio: 1.5),
    all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}");
  }
}

@mixin text-stroke($width: 1px, $color: $white) {
  -webkit-text-stroke-width: $width;
  -webkit-text-stroke-color: $color;
}

// font related

@mixin gotham-text {
  font-family: "Gotham-Bold";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@mixin gotham-light {
  font-family: "Gotham";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@mixin gotham-condensed {
  font-family: "Gotham-Condensed";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
