@import "../../../node_modules/bulma/sass/utilities/mixins.sass";

.stats-title {
  height: 24px;
  object-fit: contain;
  margin: 0 auto;
  @include tablet {
    height: 35px;
  }
}
