@import "../../../node_modules/bulma/sass/utilities/mixins.sass";


#how-to-use-feature {
  margin-bottom: 1rem;
  @include tablet {
    margin-bottom: 2rem;
  }
}

#how-to-use-feature:last-child {
  @include tablet {
    margin-bottom: 5rem;
  }
}

.split-description {
  white-space: pre-wrap;
  line-height: normal !important;
}


.use-section {
  overflow: hidden;
  @include mobile {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

.use-icon {
  // margin-top: 0.75rem;
  width: 20px;
  height: 20px;

  @include tablet {
    margin-top: initial;
    width: 40px;
    height: 40px;
  }
}

.use-text-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  &#left {
    @include tablet {
      padding-left: 4rem;
    }
  }
  &#right {
    @include tablet {
      padding-top: 4rem !important;
      padding-right: 4rem;
    }
  }
}

.use-image-standalone-container {
  overflow: hidden;
  margin-bottom: 2rem;
  .use-image-standalone {
    max-height: 340px;
    // margin-left: 4rem;
    margin-left: 20%;
  }
}
.use-container {
  position: relative;
  .column {
    z-index: 1;
  }
  .use-image-container {
    position: relative;
    padding: 0 2rem;
    height: 100vh;
    max-height: 800px;
    @include mobile {
      height: 600px;
    }
    .image {
      width: 110%;
      max-width: 575px;
      position: absolute;
      left: 15%;
      //top: 0;
      top: 4rem;
      height: 100%;

      &#right {
        @include desktop {
          //top: -10%;
          top: 1rem;
        }
      }

      .image-override {
        width: 100%;
        height: 120%;
      }
    }
  }
}
