@import "~bulma/sass/utilities/mixins.sass";

.bar-detail-description {
  padding-right: 2rem;
  @include mobile {
    padding: 0.75rem 2rem;
  }
}

.columns.reverse-wrap {
  @include mobile {
    flex-direction: column-reverse;
    display: flex;
  }
}

.bar-detail-offers {
  margin-top: 1rem;
}

.button.button-full {
  width: 100%;
  @include mobile {
    display: none;
  }
}
