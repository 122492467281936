@import "../_mixins.scss";
@import "~bulma/sass/utilities/mixins.sass";

.padding-special {
  padding-right: 2rem;
  margin-bottom: 4rem;
  @include mobile {
    padding-right: 12px;
  }
}
