@import "../../node_modules/bulma/sass/utilities/mixins.sass";

.hero-black {
  background-color: black;
}

.new-hero {
  .hero-body {
    padding-left: 2rem;
    padding-right: 2rem;
    @include mobile {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  &.is-large {
    .hero-body {
      overflow: hidden;
      padding-top: 1rem !important;
      @include tablet {
        padding-top: 12rem !important;
      }
      @include tablet {
        min-height: 50vh;
        max-height: 800px;
      }
      padding-bottom: 0 !important;

      .column:first-child {
        padding-top: 6rem;
        padding-bottom: 6rem;
        @include mobile {
          padding-top: 3rem;
        }
      }

      .image-control {
        max-height: 340px;
        height: 100%;
        @include mobile {
          // padding: 0.75rem 2.75rem 0;
        }
        video {
          width: 100%;
          height: 100%;
        }
        @include tablet {
          // max-height: 80vh;
          max-height: 400px;
        }
      }
    }
  }
}
