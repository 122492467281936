@import "../../../node_modules/bulma/sass/utilities/mixins.sass";
@import "../../mixins";
@import "../../variables";

.text-hero {
  // font-family: "Gotham Condensed";
  @include gotham-condensed;
  font-size: 100px;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.85;
  letter-spacing: normal;
  color: #ffffff;
  color: #000; //fallback
  color: rgba(0, 0, 0, 0);
  font-weight: bold;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;

  @include mobile() {
    font-size: 64px;
  }
}

.text-hero-small {
  // font-family: "Gotham Condensed";
  @include gotham-condensed;
  font-size: 70px;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.85;
  letter-spacing: normal;
  color: #000; //fallback
  color: rgba(0, 0, 0, 0);
  font-weight: bold;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
}

.text-title {
  // font-family: "Gotham Condensed";
  @include gotham-condensed;
  font-size: 80px;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.85;
  letter-spacing: normal;
  color: #ffffff;
  font-weight: bold;
}

.text-secondary-title {
  font-family: "Gotham-Bold";
  font-size: 25px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
}

.text-tertiary-title {
  // font-family: "Gotham Condensed";
  @include gotham-condensed;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 1.075;
  letter-spacing: normal;
  color: black;

  @include tablet {
    font-size: 40px;
  }
}

.text-subtitle {
  font-family: "Gotham-Medium";
  font-size: 35px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
}

.text-tag {
  font-family: "Gotham-Bold";
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  // color: #ffffff;
  opacity: 0.9;
}

.text-button {
  font-family: "Gotham-Bold";
  font-size: 25px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

  &.is-small {
    font-size: 14px;
  }
}

.text-submit-button {
  font-family: "Gotham-Bold";
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  &.is-small {
    font-size: 14px;
  }
}

.button .text-button {
  color: #000000;
}

.text-paragraph {
  font-family: "Gotham-Medium";
  font-size: 25px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #ffffff;

  @include mobile() {
    line-height: 40px;
  }
}

.text-paragraph + .text-paragraph {
  margin-top: 20px;
}

.text-secondary-paragraph {
  font-family: "Gotham-Medium";
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;

  @include mobile() {
    line-height: 40px;
  }
}

.text-tertiary-paragraph {
  font-family: "Gotham-Medium";
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.text-paragraph-book {
  font-family: "Gotham-Medium";
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
}

.text-link {
  @include gotham-condensed;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  font-size: 18px;

  @include tablet {
    font-size: 30px;
  }
}

.text-link-title {
  @include gotham-condensed;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  font-size: 18px;
  color: black;
  @include text-stroke(1px, $white);

  @include tablet {
    font-size: 30px;
  }
}

.dusk-link-title-16 {
  @include gotham-condensed;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: black; // fallback
  color: rgba(0, 0, 0, 0);
  @include text-stroke(0.75px, $white);
}

.dusk-link-16 {
  @include gotham-condensed;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}

.text-number-heading-condensed {
  @include gotham-condensed;
  line-height: 1;
  font-size: 30px;
  letter-spacing: 4px;
  color: $white; // fallback
  color: rgba(0, 0, 0, 0);
  @include text-stroke(1px, $black);
  @include tablet {
    font-size: 50px;
    @include text-stroke(2px, $black);
  }
}

.text-number-heading {
  @include gotham-text;
  line-height: 1;
  font-size: 20px;
  color: $white; // fallback
  color: rgba(0, 0, 0, 0);
  @include text-stroke(1px, $black);
  @include tablet {
    font-size: 48px;
    @include text-stroke(2px, $black);
  }
}

/**
 * Start of new typo styling
 * dusk-{font}-{p/h}-{font-size}
 * There are also some cases where p-30-20 exists. These are situations where the tablet above
 * font size is 30 and up to tablet font size is 20px.
**/

// MEDIUM

.dusk-medium-p-12 {
  font-size: 12px;
  line-height: 1.2;
}

.dusk-medium-p-20-14 {
  font-size: 14px;
  line-height: 1.2;
  @include tablet {
    font-size: 20px;
  }
}

.dusk-medium-p-20-12 {
  font-size: 12px;
  line-height: 1.2;
  @include tablet {
    font-size: 20px;
  }
}

.dusk-medium-p-25 {
  font-size: 14px;
  line-height: 1.2;
  @include tablet {
    font-size: 25px;
  }
}


// BOLD

.dusk-p-14 {
  @include gotham-text;
  font-size: 12px;
  line-height: 1.2;
  @include tablet {
    font-size: 14px;
    line-height: 1.07;
  }
}

.dusk-p-16 {
  @include gotham-text;
  font-size: 12px;
  line-height: 2;
  @include tablet {
    font-size: 16px;
  }
}

.dusk-p-18 {
  @include gotham-text;
  font-size: 14px;
  line-height: 1.2;
  @include tablet {
    font-size: 18px;
  }
}

.dusk-p-20 {
  @include gotham-text;
  font-size: 14px;
  line-height: 1.2;
  @include tablet {
    font-size: 20px;
  }
}

.dusk-p-25 {
  @include gotham-text;
  font-size: 14px;
  line-height: 1.2;
  @include tablet {
    font-size: 25px;
    line-height: 1.78;
  }
}

.dusk-p-error-14 {
  @include gotham-text;
  line-height: 1.2;
  font-size: 14px;
  color: $danger;
  @include tablet {
    font-size: 20px;
  }
}

// LIGHT

.dusk-light-p-16 {
  @include gotham-light;
  font-size: 14px;
  line-height: 1.07;
  @include tablet {
    font-size: 16px;
  }
}

// CONDENSED

.dusk-condensed-p-16 {
  @include gotham-condensed;
  font-size: 16px;
  line-height: 1.075;
}

.dusk-condensed-p-20 {
  @include gotham-condensed;
  line-height: 1.075;
  font-size: 20px;
}

.dusk-condensed-p-30 {
  @include gotham-condensed;
  font-size: 30px;
  line-height: 1.075;
}

.dusk-condensed-p-50 {
  @include gotham-condensed;
  font-size: 25px;
  line-height: 1.075;
  @include tablet {
    font-size: 50px;
  }
}

// HEADING

.dusk-h1-160 {
  @include gotham-condensed;
  font-size: 100px;
  line-height: 0.8125;
  @include tablet {
    font-size: 160px;
  }
}

.dusk-h1-110 {
  @include gotham-condensed;
  font-size: 40px;
  line-height: 3;
  @include tablet {
    font-size: 110px;
    line-height: 1.09;
  }
}

.dusk-h1-100 {
  @include gotham-condensed;
  font-size: 64px;
  line-height: 1.05;
  @include tablet {
    font-size: 100px;
  }
}

.dusk-h1-80 {
  @include gotham-condensed;
  font-size: 45px;
  line-height: 0.875;
  @include tablet {
    font-size: 80px;
  }
}

.dusk-h1-80-64 {
  @include gotham-condensed;
  font-size: 64px;
  line-height: 0.85;
  @include tablet {
    line-height: 0.875;
    font-size: 80px;
  }
}

.h1-text-shadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

// OUTLINED HEADING

.dusk-h1-outline-80 {
  @include gotham-condensed;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #000; //fallback
  color: rgba(131, 124, 124, 0);
  font-weight: bold;
  font-size: 45px;
  @include text-stroke(1px, $white);
  @include tablet {
    font-size: 80px;
    //@include text-stroke(2px, $white);
  }
}

.dusk-h1-outline-100 {
  @include gotham-condensed;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.78;
  letter-spacing: normal;
  color: #000; //fallback
  color: rgba(0, 0, 0, 0);
  font-weight: bold;
  font-size: 64px;
  @include text-stroke(1.2px, $white);
  @include tablet {
    font-size: 100px;
    line-height: 0.8;
  }
}

.dusk-half-m-bot {
  margin-bottom: 20px !important;
}

.dusk-half-m-bot + * {
  margin-top: 0 !important;
}

.dusk-m-bot {
  margin-bottom: 40px !important;
}

.dusk-m-bot + * {
  margin-top: 0 !important;
}

.dusk-m-top-xl {
  margin-top: 200px !important;

  @include mobile() {
    margin-top: 100px !important;
  }
}

.dusk-m-top-l {
  margin-top: 100px !important;

  @include mobile() {
    margin-top: 50px !important;
  }
}

.dusk-m-top-s {
  margin-top: 40px;

  @include mobile() {
    margin-top: 20px !important;
  }
}

.dusk-m-bot-full {
  margin-top: 200px !important;

  @include mobile() {
    margin-top: initial !important;
    margin-bottom: 1rem;
  }
}
