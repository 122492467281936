.dusk-section-image {
  min-height: 80vh;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: -1;
    background-size: cover;
    background-position: center;
  }
  &#work::before {
    background-image: url("../assets/images/backgroundWorkWithUs.jpeg");
  }
  &#download::before {
    background-image: url("../assets/images/backgroundDownload.jpeg");
  }
}
