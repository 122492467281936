@import "../../../node_modules/bulma/sass/utilities/mixins.sass";

@import "../../variables";

.bg-white {
  background-color: $dark-white;
}

.container-how {
  padding: 2rem 1rem;
  text-align: center;

  .columns {
    padding: 2rem 0;
    @include tablet {
      padding: 1rem 0 2rem 0;
    }
  }
}

.how-it-works-image {
  width: 70px;
  height: 70px;
  object-fit: contain;
  @include tablet {
    width: 90px;
    height: 90px;
  }

  &#compass {
    width: 60px;
    height: 70px;
    @include tablet {
      width: 70px;
      height: 90px;
    }
  }
}
