@import "../../node_modules/bulma/sass/utilities/mixins.sass";

.phone-frame {
  width: 100%;
  height: 100%;
  @include tablet {
    width: 575px;
  }
  position: relative;
  @include mobile {
    margin-left: 1rem; // minor adjustment to make the image appear more centered
  }

  .frame {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 0; // not sure why this is a problem sometimes! so -10% is used
    bottom: -10%; 
  }

  .frame-underlay {
    object-fit: contain;
    object-position: center;
    border-radius: 5%;
    width: 90%;
    margin-top: 5%;
    margin-left: 5%;
    
    &.shadow {
      width: 56%;
      margin-top: 6.7%;
      margin-left: 4.5%;
    }
  }
}
