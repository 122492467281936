@import "~bulma/sass/utilities/mixins.sass";

.tags {
  margin-top: 1rem;
  margin-bottom: 0 !important;
}

.listing-image {
  @include mobile {
    width: 100%;
  }
  border: 3px solid #fff;
  cursor: pointer;
}

.listing-text {
  margin: 0 auto;
}

.listing-title {
  margin: 0 auto 1rem auto;
  text-align: left;
}

.blur {
  filter: blur(8px);
  border: none !important;
}

.read-more {
  display: block;
  font-weight: 700;
  text-align: left;
}
