@import '../../node_modules/bulma/sass/utilities/mixins.sass';

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  // color: #a1a0a1 !important;
  color: white !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  // color: #a1a0a1;
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  // color: #a1a0a1;
  color: white;
}

.dusk-field {
  margin-bottom: 0.5rem !important;
  @include tablet {
    margin-bottom: 1.5rem !important;
  }
  .input {
    border: 1px solid white;
    border-radius: 5px;
    @include tablet {
      border-radius: 15px;
    }
    &:hover {
      border-color: initial;
    }
  }
}

.dusk-field textarea {
  border: 2px solid white;
  border-radius: 5px;
  font-family: 'Gotham-Medium';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  @include tablet {
    border-radius: 15px;
    font-size: 20px;
  }
}

.dusk-field input[type='text'],
.dusk-field input[type='tel'],
.dusk-field input[type='email'] {
  // height: 60px;
  height: 33px;
  font-family: 'Gotham-Medium';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  @include tablet {
    height: 47px;
    font-size: 20px;
  }
}

.dusk-field .control.has-icons-left .icon,
.dusk-field .control.has-icons-right .icon {
  // width: 60px;
  // height: 60px;

  width: 33px;
  height: 33px;
  img {
    margin-left: 0.25rem;
    width: 14px;
    height: 14px;
  }
  @include tablet {
    width: 47px;
    height: 47px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.dusk-field .control.has-icons-left .input {
  padding-left: 3em;
  @include tablet {
    padding-left: 3em;
  }
}

.dusk-field-dark ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  // color: #a1a0a1 !important;
  color: black !important;
  opacity: 1; /* Firefox */
}

.dusk-field-dark :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  // color: #a1a0a1;
  color: black;
}

.dusk-field-dark ::-ms-input-placeholder {
  /* Microsoft Edge */
  // color: #a1a0a1;
  color: black;
}

.dusk-field-dark {
  .input {
    border: 1px solid black;
  }
}

.dusk-field-dark textarea {
  border: 2px solid black;
  color: black;
}

.dusk-field-dark input[type='text'],
.dusk-field-dark input[type='tel'],
.dusk-field-dark input[type='email'] {
  color: black;
}

.input-icon svg path {
  fill: black;
}
