.control .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    margin-top: 1.5em;
}

.image-input .body-secondary {
    margin-bottom: 1.5em;
}

#get-the-app .mobile-store-link {
    display: block;
    align-items: center;
}

#get-the-app .android-btn {
    margin-top: 10px;
}