@import "~bulma/sass/utilities/mixins.sass";

.not-found-hero {
  text-align: center;
}

.video-container {
  position: relative;
  margin: 2rem auto 0 auto;
  height: 0;
  padding-top: 25px;
	padding-bottom: 56.25%; /* 16:9 */
  border: 3px solid #fff;

  .video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
