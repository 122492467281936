@import "~bulma/sass/utilities/mixins.sass";

.section.loading {
  text-align: center;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    height: calc(100vh - 52px);
  }

  .loading-image {
    width: 350px;

    @include mobile {
      width: 100%;
      height: auto;
    }
  }
}
