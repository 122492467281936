@import "~bulma/sass/utilities/mixins.sass";

.edit-detail-description {
  padding-right: 2rem;
  @include mobile {
    padding: 0.75rem 2rem;
  }
}

.columns.reverse-wrap {
  @include mobile {
    flex-direction: column-reverse;
    display: flex;
  }
}

.button.button-full {
  width: 100%;
  @include mobile {
    display: none;
  }
}

.section.edit-page {
  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }
}