@import "../../node_modules/bulma/sass/utilities/mixins.sass";

.brand-testimonial-logo {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  max-width: 180px;
  object-fit: contain;
  &.active {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;
}

.brand-testimonial-text {
  opacity: 1;
  margin: 2rem 0;
  position: relative;
  animation: fadeIn 0.4s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    bottom: -20px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
