@import '../_mixins.scss';


.hero.home-primary-hero {
    background-image: url("../assets/images/heroHome.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.hero.home-feature-hero {
    @include background-2x('../assets/images/heroFeatureBar', 'png');
}