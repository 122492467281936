@import "../../node_modules/bulma/sass/utilities/mixins.sass";
@import "../variables";

.section-dusk-columns {
  display: flex;
  height: 100%;
  @include mobile {
    flex-direction: column;
  }
}

.section,
.section-dusk {
  &.dusk-bg-dark {
    p,
    h1,
    h2,
    h3 {
      color: $white;
    }
  }
  &.dusk-bg-light {
    p,
    h1,
    h2,
    h3 {
      color: $black;
    }
  }
}

.section-dusk {
  display: flex;
  min-height: 700px;
  &.mobile-small {
    @include mobile {
      min-height: 550px;
    }
  }
  padding-bottom: 0 !important;
  overflow: hidden;
  @include tablet {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @include desktop {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .section-dusk-text-column {
    padding: 0 2rem;
    @include mobile {
      padding-top: 4rem;
      order: 1;
      flex-grow: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1,
    p {
      margin-bottom: 2rem;
    }
  }

  .section-dusk-body {
    // overflow: hidden;
  }
  .section-dusk-image-column {
    position: relative;
    align-self: stretch;
    @include mobile {
      order: 2;
      align-self: center;
    }
  }
  .section-dusk-image-container {
    @include tablet {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 140%;
      padding: 1rem 1rem 0 5rem;
      max-height: 700px;
      // max-height: 80vh;
      margin: initial;
    }
    .image {
      height: 100%;
      max-height: 80vh;
      max-height: 600px;
    }
    margin: 2rem 0 0 1.5rem;
    max-height: 300px;
    width: 123%;
  }
}
