@import "../../../node_modules/bulma/sass/utilities/mixins.sass";

.footer-style {
  margin-top: 50px;
  @include tablet {
    margin-top: 80px;
  }
}

.footer-title {
  margin-bottom: 12px;
  @include tablet {
    margin-bottom: 35px;
  }
}

.social-stack {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  @include tablet {
    margin-bottom: 2rem;
  }

  .social {
    margin: 0 0.5rem;
    @include tablet {
      margin: 0 1rem;
    }
  }
}

.footer-copyright {
  font-size: 10px;
  @include tablet {
    font-size: 20px;
  }
}
