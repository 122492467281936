@import "./variables";
@import "../node_modules/bulma/sass/utilities/mixins.sass";

.dusk-negate-t-padding {
  padding-top: 0 !important;
}

.error-text {
  // color: #ac2323;
  color: $danger;
}

.dusk-bg-dark {
  background-color: $dark;
}

.dusk-bg-light {
  background-color: $dark-white;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-start-tablet {
  @include tablet {
    align-items: flex-start;
  }
}

.is-align-items-center {
  align-items: center;
}

.is-align-items-center-mobile {
  @include mobile {
    align-items: center;
  }
}

.is-justify-content-center {
  justify-content: center;
}

.is-flex-direction-column {
  flex-direction: column;
}

@for $i from 1 to 8 {
  .m-#{$i} {
    margin: #{$i * 0.5 * 1}rem !important;
  }
  .m-sm-#{$i} {
    @include mobile {
      margin: #{$i * 0.5 * 1}rem !important;
    }
  }
  .mb-#{$i} {
    margin-bottom: #{$i * 0.5 * 1}rem !important;
  }
  .mb-md-#{$i} {
    @include tablet {
      margin-bottom: #{$i * 0.5 * 1}rem !important;
    }
  }
  .mb-sm-#{$i} {
    @include mobile {
      margin-bottom: #{$i * 0.5 * 1}rem !important;
    }
  }
  .mt-#{$i} {
    margin-top: #{$i * 0.5 * 1}rem !important;
  }
  .mt-sm-#{$i} {
    @include mobile {
      margin-top: #{$i * 0.5 * 1}rem !important;
    }
  }
  .ml-#{$i} {
    margin-left: #{$i * 0.5 * 1}rem !important;
  }
  .ml-sm-#{$i} {
    @include mobile {
      margin-left: #{$i * 0.5 * 1}rem !important;
    }
  }
  .mr-#{$i} {
    margin-right: #{$i * 0.5 * 1}rem !important;
  }
  .mr-sm-#{$i} {
    @include mobile {
      margin-right: #{$i * 0.5 * 1}rem !important;
    }
  }
  .mr-md-#{$i} {
    @include tablet {
      margin-right: #{$i * 0.5 * 1}rem !important;
    }
  }
  .mx-#{$i} {
    margin-left: #{$i * 0.5 * 1}rem !important;
    margin-right: #{$i * 0.5 * 1}rem !important;
  }
  .mx-sm-#{$i} {
    @include mobile {
      margin-left: #{$i * 0.5 * 1}rem !important;
      margin-right: #{$i * 0.5 * 1}rem !important;
    }
  }
  .my-#{$i} {
    margin-top: #{$i * 0.5 * 1}rem !important;
    margin-bottom: #{$i * 0.5 * 1}rem !important;
  }
  .my-sm-#{$i} {
    @include mobile {
      margin-top: #{$i * 0.5 * 1}rem !important;
      margin-bottom: #{$i * 0.5 * 1}rem !important;
    }
  }
  .p-#{$i} {
    padding: #{$i * 0.5 * 1}rem !important;
  }
  .p-sm-#{$i} {
    @include mobile {
      padding: #{$i * 0.5 * 1}rem !important;
    }
  }
  .pb-#{$i} {
    padding-bottom: #{$i * 0.5 * 1}rem !important;
  }
  .pb-sm-#{$i} {
    @include mobile {
      padding-bottom: #{$i * 0.5 * 1}rem !important;
    }
  }
  .pt-#{$i} {
    padding-top: #{$i * 0.5 * 1}rem !important;
  }
  .pt-sm-#{$i} {
    @include mobile {
      padding-top: #{$i * 0.5 * 1}rem !important;
    }
  }
  .pl-#{$i} {
    padding-left: #{$i * 0.5 * 1}rem !important;
  }
  .pl-sm-#{$i} {
    @include mobile {
      padding-left: #{$i * 0.5 * 1}rem !important;
    }
  }
  .pr-#{$i} {
    padding-right: #{$i * 0.5 * 1}rem !important;
  }
  .pr-sm-#{$i} {
    @include mobile {
      padding-right: #{$i * 0.5 * 1}rem !important;
    }
  }
  .px-#{$i} {
    padding-left: #{$i * 0.5 * 1}rem !important;
    padding-right: #{$i * 0.5 * 1}rem !important;
  }
  .px-sm-#{$i} {
    @include mobile {
      padding-left: #{$i * 0.5 * 1}rem !important;
      padding-right: #{$i * 0.5 * 1}rem !important;
    }
  }
  .py-#{$i} {
    padding-top: #{$i * 0.5 * 1}rem !important;
    padding-bottom: #{$i * 0.5 * 1}rem !important;
  }
  .py-sm-#{$i} {
    @include mobile {
      padding-top: #{$i * 0.5 * 1}rem !important;
      padding-bottom: #{$i * 0.5 * 1}rem !important;
    }
  }
}

.is-pointer-click {
  cursor: pointer;
}
