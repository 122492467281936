.section.premium-terms p{
    margin-bottom: 20px;
}

.section.premium-terms ul {
    list-style-type: circle;
    padding-left: 40px;
}

.section.premium-terms ol {
    padding-left: 40px;
}

.section.premium-terms span.b {
    font-family: 'Gotham-Bold';
}