.section.privacy-policy p {
    margin-bottom: 20px;
}

.section.privacy-policy ul {
    list-style-type: circle;
    padding-left: 40px;
}

.section.privacy-policy span.b {
    font-family: 'Gotham-Bold';
}