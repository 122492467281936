
#tracking-intro {
    .btn-white {
        margin-top: 40px;
    }

    .privacy-policy-container {
        display: block;
        margin-top: 30px;

        > a {
            font-family: 'Gotham-Medium';
            font-size: 14px;
            text-align: center;
    
            color: white;
        }
    }
}

#covid-tracking-form {
    .checkbox {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;

        &:hover {
            color: white;
        }

        input {
            margin-right: 10px;
        }

        a:hover {
            color: white;
        }
    }

    .error-text {
        margin-bottom: 10px;
    }

    .people-counter-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .people-counter {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 10px;

            p {
                font-size: 40px;
                margin-left: 20px;
                margin-right: 20px;
            }

            .counter-btn {
                cursor: pointer;
                width: 34px;
                height: 34px;
                background-color: #4C4C4C;
                border-radius: 17px;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.navbar.track-trace {
    .navbar-brand {
        flex-grow: 1;
        height: 65px;
    }
    .navbar-item img.track-logo {
        max-height: 47px;
    }
}

#tracking-confirm {
    .btn-white {
        margin-top: 40px;
    }
}

/* Text Style Overrides*/
#track-trace-container {
    .mt20 {
        margin-top: 20px;
    }
    .text-title {
        font-size: 48px;
    }

    .text-paragraph {
        font-size: 18px;
        line-height: 26px;
    }

    .button {
        height: 44px;
        background-color: white !important;

        > .text-button {
            color: black;
            font-size: 15px;
        }

        &.is-loading .text-button {
            color: transparent;
        }

        &.is-primary.is-loading::after {
            border-color: transparent transparent #000 #000 !important;
        }
    }
    
    .track-field {
        margin-bottom: 40px;

        input[type=text], input[type=tel], input[type=email] {
            height: 44px;
            font-family: "Gotham-Medium";
            font-size: 18px;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
    
            margin-top: 5px;
        }
    }
    
    .dusk-field .control.has-icons-left .icon, .dusk-field .control.has-icons-right .icon {
        width: 60px;
        height: 60px;
    }

    video.confirm-tick {
        width: 200px;
        margin-bottom: 20px;
    }
}