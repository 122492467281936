// Set your brand colors
$green: #0a561b;
$purple: #2b0740;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$black: #000000;
$dark: #1b1b1b;
$white: #ffffff;
$dark-white: #f3f3f3;
$danger: #D71E1E;
