@charset "utf-8";
@import "./variables";

// Update Bulma's global variables
$family-sans-serif: "Gotham-Medium", sans-serif;
$primary: $white;
$link: $white;
$text: $white;
$text-strong: $white;
$grey-darker: #1e1e1e;
$widescreen-enabled: false;
$fullhd-enabled: false;
$desktop: 1280px;
$widescreen: 1280px;
$body-background-color: $black;
$body-color: $white;
$strong-color: $white;
$weight-normal: 400;

// Navigation Styles
$navbar-background-color: transparent;

// Title Styles
$title-color: $white;
$title-weight: $weight-normal;
$title-family: "Gotham-Bold", sans-serif;
$subtitle-color: $white;
$subtitle-weight: $weight-normal;

// Input Styles
$input-color: $white;
$input-background-color: rgba(0, 0, 0, 0);

// Footer Styles
$footer-background-color: $black;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$primary-invert: #000000;

@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
// Elements
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/elements/tag.sass";
// Components
@import "../node_modules/bulma/sass/components/level.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
// Layout
@import "../node_modules/bulma/sass/layout/footer.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";

// Grid
@import "../node_modules/bulma/sass/grid/columns.sass";
