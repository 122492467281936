@import "../../../node_modules/bulma/sass/utilities/mixins.sass";

@import "../../variables";

.bg-black {
  background-color: black;
}

.bg-white {
  background-color: white;
}

.section-testimonial {
  display: flex;
  margin: 10px 0;
  min-height: 300px;
  text-align: center;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @include mobile {
    min-height: 120px;
    padding: 2rem 1rem !important;
  }
}

.container-testimonial {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.testimonial-card {
  width: 30vw;
  border-radius: 15px;
  padding: 20px;
  margin: 10px;
  max-width: 400px;
  @include tablet {
    width: 40vw;
  }
  @include mobile {
    width: 80vw;
    max-width: 300px;
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
