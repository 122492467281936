@import "~bulma/sass/utilities/mixins.sass";
@import "../../mixins";

.dusk-link-item + .dusk-link-item {
  border-top: 0.5px solid white;
}
.dusk-link-item-desktop {
  border-bottom: 0.5px solid white;
}

.dusk-link-item {
  padding: 0.5rem 0;
  margin: 0 3rem;
}

.dusk-link-item-hover {
  &:hover {
    color: white !important;
    color: rgba(0, 0, 0, 0) !important;
    @include text-stroke(1px);
  }
  p {
    &:hover {
      color: white !important;
      color: rgba(0, 0, 0, 0) !important;
      @include text-stroke(1px);
    }
  }
}

.dusk-link-item-desktop {
  padding: 0.5rem 0;
  cursor: pointer;
  // &:hover {
  //   color: white !important;
  //   color: rgba(0, 0, 0, 0) !important;
  //   @include text-stroke(1px);
  // }
}

// .dusk-open-nav {
//   pointer-events: none;
//   height: 114px;
//   max-height: 0;
//   &.is-open {
//     pointer-events: all;
//     max-height: 114px;
//   }
//   transition: max-height 0.2s ease-in-out;
// }

.dusk-dropdown-parent {
  position: relative;

  .dusk-dropdown-child {
    display: none;
    width: 100%;
  }
}

.dusk-dropdown-parent:hover {
  position: relative;

  .dusk-dropdown-child {
    display: block;
  }
}

.navbar-burger.burger {
  span {
    color: white;
  }
}
