@import "../../../node_modules/bulma/sass/utilities/mixins.sass";

.work-with-us-buttons {
  .column {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
    @include tablet {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
